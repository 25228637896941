html,
body {
  height: 100%;
}

#app {
  overflow: auto;
  height: 100%;
}

a {
  text-decoration: none;
}

.swiper {
  width: 100%;
  height: 100%;
}

.help-content {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}

ul.help-content {
  padding-left: 24px;
}

td.help-content {
  padding-bottom: 16px;
  white-space: nowrap;

  &:first-child {
    padding-right: 24px;
    white-space: normal;
  }
}

.link {
  text-decoration: none;
  color: #6387e6;

  &:hover {
    color: #455ea1;
  }

  &:active {
    color: #455ea1;
  }

  &:visited {
    color: #6387e6;
  }
}
